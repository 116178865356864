import { useEffect } from "react";
import { useRouter } from "next/router";
var NotFoundPage = function NotFoundPage() {
  var router = useRouter();
  useEffect(function () {
    // Redirect to the default route after 3 seconds
    var redirectTimer = setTimeout(function () {
      router.push("/"); // Replace with your default route
    }, 100);
    return function () {
      return clearTimeout(redirectTimer);
    };
  }, [router]);
  return;
};
export default NotFoundPage;